.login-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 10;
}
.login-inner-box {
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  width: 360px;
  margin: 24px 0 80px 0;
  padding: 0 16px;
}
.login-inner-box-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.login-title {
  width: 100%;
  color: #171e26;
  text-align: start;
  font-size: 26px;
  line-height: 34px;
  font-family: Pretendard-Bold;
  display: none;
}
.snsjoin {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.snsjoin-title {
  width: 100%;
  font-family: Pretendard-Medium;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sns-login-btns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.login-middle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.login-middle-line {
  height: 1px;
  display: flex;
  flex: 1;
  background-color: rgba(23, 30, 38, 0.1);
}
.login-middle-text {
  color: rgba(23, 30, 38, 0.4);
  font-size: 12px;
}
.email-login-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.email-login-title {
  width: 100%;
  font-family: Pretendard-Medium;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-box {
  width: 100%;
}
.input-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}
.input-inner input {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 50px;
  border-radius: 10px;
  padding: 12px 14px;
  border: 1px solid #e6e6e6;
  font-family: Pretendard-Medium;
  font-size: 16px;
}
.input-inner button {
  cursor: pointer;
}
.control-id-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.remember-id {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.remember-id img {
  width: 18px;
  height: 18px;
}
.remember-id-text {
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
  color: #171e26;
}
.find-id {
  font-family: Pretendard-Medium;
  font-size: 14px;
  cursor: pointer;
  color: #171e26;
}
.join-member-box {
  width: 100%;
}

.joinbubble {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-family: Pretendard-Medium;
  margin-bottom: 20px;
  animation: joinbubble 0.5s linear infinite alternate;
  position: relative;
}
.joinbubble div {
  height: 50px;
  width: 265px;
  border-radius: 100px;
}
.joinbubble::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -12px;
  content: '';
  display: block;
  border: 7px solid transparent;
  border-top: 14px solid #000;
  border-bottom: 0;
  transform: translateX(-50%);
}
.joinbubble-text {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #000;
  z-index: 10;
}
.joinbubble-text img {
  width: 20px;
}
@keyframes joinbubble {
  0% {
    top: 0;
  }
  100% {
    top: -4px;
  }
}
.free {
  color: #f8d857;
}

@media (min-width: 768px) {
  .login-inner-box {
    margin: 80px 0 80px 0;
  }
}
@media (min-width: 1024px) {
  .login-title {
    display: block;
  }
  .login-wrap {
    justify-content: space-between;
  }
  .login-inner-box {
    padding: 0 20px;
    width: 468px;
    margin: 80px 0 80px 0;
  }
}
